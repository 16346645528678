import { Fragment } from "react"

import Layout from "../components/Layout"
import DefaultHeader from "../components/DefaultHeader"
import Section from "../components/Section"
import Background from "../components/Background"
import { Flex, Heading } from "rebass/styled-components"
import { centerHorizontally } from "../styles/constants"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import * as React from "react"

const LegalDisclosurePage = () => (
  <Layout>
    <SEO title="Legal Disclosure" />
    <DefaultHeader />
    <div>
      <Section.Container id="legalDisclosure" Background={Background}>
        <Fragment>
          <div style={centerHorizontally}>
            <Heading
              as="h3"
              color="primary"
              fontSize={[3, 4]}
              mb={[2, 3, 4]}
              textAlign="center"
            >
              LEGAL DISCLOSURE
            </Heading>
            <p>Information in accordance with Section 5 TMG</p>
            <p>
              Marten Schwarzmann
              <br />
              Gärtnerstr. 62D
              <br />
              80992 München
            </p>
            <Heading
              as="h4"
              color="primary"
              fontSize={[2, 3]}
              mb={[1, 2, 3]}
              textAlign="center"
            >
              Contact Information
            </Heading>
            <p>
              Telephone: +49 176 87843870
              <br />
              E-Mail:{" "}
              <a href="mailto:burritoapps@gmail.com">burritoapps@gmail.com</a>
              <br />
              Internet address:{" "}
              <a
                href="https://www.burritoapps.de/"
                rel="noreferrer"
                target="_blank"
              >
                www.burritoapps.de
              </a>
            </p>
            <Flex
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            ></Flex>
          </div>
        </Fragment>
      </Section.Container>
    </div>
    <Footer />
  </Layout>
)

export default LegalDisclosurePage
